(function ($) {
  'use strict';

  // Handle tabs in content.
  Drupal.behaviors.dexcom_tabs = {
    attach: function (context, settings) {
      var openTab = Drupal.behaviors.dexcom_tabs.getUrlParameter('tab');
      var headerHeight = Math.max($('.header').outerHeight(), $('.header .header__bar').outerHeight());
      $('.tabHolder__link').each(function () {
        $(this).once('tab-link', function () {
          $(this).click(function (event) {
            var dataId = $(this).data('id');
            dataId = dataId.slice(11);
            event.preventDefault();
            Drupal.behaviors.dexcom_tabs.toggleTab(dataId);
            // Set path.
            history.pushState({path: this.path}, '', '?tab=' + dataId);
          });
        });
      });

      // Using URL query parameter, open tab.
      if (typeof openTab !== 'undefined') {
        Drupal.behaviors.dexcom_tabs.toggleTab(openTab);
        headerHeight += 70;
        // Let's now scroll to the tabs element.
        $(window).load(function () {
          $('html, body').animate({
            scrollTop: $('#block-system-main .tabHolder__tabs').offset().top - headerHeight
          }, 1000);
        });
      }
    },

    // Get parameters from URL.
    getUrlParameter: function (sParam) {
      var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;
      for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
          return sParameterName[1];
        }
      }
    },

    toggleTab: function (dataId) {
      var newDataId = 'tabHolder--' + dataId;
      // If a tab value is passed that does not exist, go to the first tab.
      if ($('#' + newDataId).length === 0) {
        newDataId = 'tabHolder--0';
        history.pushState({path: this.path}, '', '?tab=0');
      }

      if (!$('#' + newDataId).hasClass('active')) {
        $('.tabHolder__link.active').removeClass('active');
        $('.tabHolder__content.active').removeClass('active');
        $('#' + newDataId).addClass('active');
        $('.' + newDataId + '-link').addClass('active');
      }
    }
  };
}(jQuery));
