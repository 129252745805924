(function ($) {
  'use strict';

  Drupal.dexcom_form = {};

  Drupal.dexcom_form.categoriesFAQ = function () {
    $('.form-item-category').on('change', function (event) {
      var _this = $(this);
      $('#views-exposed-form-questions-search').find(':submit').click();
    });
  };

  if (Drupal.jsAC) {
    Drupal.jsAC.prototype.select = function (node) {
      this.input.value = $(node).data('autocompleteValue');
      if (jQuery(this.input).hasClass('form-autocomplete')) {
        this.input.form.submit();
      }
    };
  }

  Drupal.behaviors.dexcom_form = {
    attach: function (context, settings) {
      Drupal.dexcom_form.categoriesFAQ();
    }
  };
}(jQuery));
