(function ($) {
  'use strict';

  Drupal.dexcom_paidsearch = {};

  Drupal.dexcom_paidsearch.getQueryParameters = function (queryString) {
    var params = {},
      queries,
      temp,
      i,
      j;

    // Split into key/value pairs
    queries = queryString.split('&');

    // Convert the array of strings into an object
    for (i = 0, j = queries.length; i < j; i++) {
      temp = queries[i].split('=');
      params[temp[0]] = temp[1];
    }

    return params;
  };

  Drupal.dexcom_paidsearch.getMatchingLinks = function () {
    return $('a[href*="\/lead\/"]');
  };

  Drupal.dexcom_paidsearch.updateLinks = function ($links, appendString) {
    var separator = '?',
      href;

    $links.each(function () {
      href = $(this).attr('href');
      if (href.indexOf('?') > -1) {
        separator = '&';
      }
      $(this).attr('href', href + separator + appendString);
    });
  };

  Drupal.dexcom_paidsearch.init = function () {
    var ps = Drupal.dexcom_paidsearch,
      params = ps.getQueryParameters(window.location.search.substring(1)),
      $links = ps.getMatchingLinks();

    $.each(params, function (key, val) {
      if (key === 'gclid') {
        ps.updateLinks($links, 'utm_source=website&utm_campaign=70133000001LlpgAAC');
        return false;
      } else if (key === 'utm_campaign' && val === '70133000001Llq5AAC') {
        ps.updateLinks($links, 'utm_source=website&utm_campaign=70133000001Llq5AAC');
        return false;
      }
    });
  };

  Drupal.behaviors.dexcom_paidsearch = {
    attach: function (context, settings) {
      Drupal.dexcom_paidsearch.init();
    }
  };
}(jQuery));
