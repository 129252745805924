(function ($) {
  'use strict';

  Drupal.dexcom_analytics = {};

  Drupal.dexcom_analytics.analytics = function () {
    var eoi = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
      links = document.querySelectorAll('a'),
      i = 0,
      j,
      header = null,
      isVideo = false,
      isPerson = false,
      isBreadcrumb = false;

    window.analyticsStore = [];
    window.closestHeading = null;

    for (i = 0; i < links.length; i++) {
      // Get Headers
      // Set the parent to the container of the link
      links[i].setAttribute('data-module-class', links[i].parentElement.parentElement.className);

      if (links[i].className.toLowerCase().indexOf('video') > -1) {
        isVideo = true;
      }

      if (document.querySelectorAll('.breadcrumb--last').length) {
        isBreadcrumb = true;
      }

      // Check the type of link
      if (links[i].className.toLowerCase().indexOf('btn') > -1) {
        links[i].setAttribute('data-type', 'button');
      } else if (links[i].className.toLowerCase().indexOf('cta') > -1) {
        links[i].setAttribute('data-type', 'cta');
      } else if (links[i].querySelectorAll('img').length) {
        links[i].setAttribute('data-type', 'image');
      } else {
        links[i].setAttribute('data-type', 'link');
      }


      if (isBreadcrumb) {
        links[i].setAttribute('data-breadcrumb', document.querySelector('.breadcrumb--last').textContent.trim());
      }

      links[i].setAttribute('data-template-name', document.querySelector('body').className);

      // Get the titles
      header = Drupal.dexcom_analytics.searchElems(links[i]);

      // Check if analytics object is present
      if (typeof window.analyticsStore === 'object') {
        // Loop through the header
        for (j = 0; j < window.analyticsStore.length; j++) {
          links[i].setAttribute('data-' + window.analyticsStore[j].elementName.toString(), window.analyticsStore[j].elementValue.toString());
          if (isVideo) {
            links[i].setAttribute('data-video-title', window.analyticsStore[0].elementValue.toString());
          }
        }
      }
      header = null;
      isBreadcrumb = false;
      isVideo = false;
      window.analyticsStore = [];
    }
  };

  Drupal.dexcom_analytics.searchElems = function (link, current, limit, elements) {
    var eoi = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
      parent = link.parentElement,
      gotTopHeading = false,
      currentRound,
      i,
      newElements = elements,
      newLimit = limit;

    if (typeof newElements === 'undefined') {
      newElements = [];
    }

    if (isNaN(newLimit)) {
      newLimit = 0;
    }

    if (current) {
      parent = current.parentElement;
    }

    if (parent === null || parent.tagName.toLowerCase() === 'body') {
      return false;
    }

    for (i = 0; i < eoi.length; i++) {
      currentRound = parent.querySelectorAll(eoi[i]);

      if (currentRound.length) {
        if (currentRound[0].tagName.toLowerCase() === 'h1') {
          gotTopHeading = true;
        }

        if (!Drupal.dexcom_analytics.containsHeading(currentRound[0].tagName.toLowerCase(), window.analyticsStore)) {
          window.analyticsStore.push({
            elementName: currentRound[0].tagName.toLowerCase(),
            elementValue: currentRound[0].textContent.trim()
          });
        }
      }
      currentRound = null;
    }

    if (!(window.analyticsStore && gotTopHeading)) {
      Drupal.dexcom_analytics.searchElems(link, parent, newLimit + 1, newElements);
    } else {
      return true;
    }
  };

  Drupal.dexcom_analytics.containsHeading = function (heading, theData) {
    var isGotten = false,
      i;
    for (i = 0; i < theData.length; i++) {
      if (window.analyticsStore[i].elementName === heading) {
        isGotten = true;
      }
    }

    return isGotten;
  };

  Drupal.behaviors.dexcom_analytics = {
    attach: function (context, settings) {
      Drupal.dexcom_analytics.analytics();
    }
  };
}(jQuery));
