(function ($) {
  'use strict';

  Drupal.dexcom_timeline = {};

  Drupal.dexcom_timeline.currentItem = null;

  Drupal.dexcom_timeline.findMiddleElement = function (e) {
    var activeZone = document.documentElement.clientHeight * 0.4,
      $items = $('.timeline__item'),
      $midItem,
      currentItem = null;

    if (e && e.type === 'resize') {
      activeZone = document.documentElement.clientHeight * 0.4;
    }

    $items.each(function () {
      var posTop = this.getBoundingClientRect().top,
        posBottom = this.getBoundingClientRect().bottom;

      if (posTop < activeZone && posBottom > activeZone) {
        $midItem = $(this);
        return false;
      }
    });

    if ($midItem && $midItem.length && this.currentItem !== $midItem[0]) {
      $items.removeClass('middle');
      $midItem.addClass('middle');
      this.currentItem = $midItem[0];
    }
  };

  Drupal.dexcom_timeline.bindUI = function () {
    $(window).on('load scroll resize', Drupal.dexcom_timeline.findMiddleElement);
  };

  Drupal.behaviors.dexcom_timeline = {
    attach: function (context, settings) {
      if ($('.timeline').length) {
        Drupal.dexcom_timeline.bindUI();
      }
    }
  };
}(jQuery));
