(function($) {
  'use strict';

  Drupal.dexcom_slider = {};

  Drupal.behaviors.dexcom_slider = {
    attach: function(context, settings) {
      var flexSliders = $('.flexslider');
      var carousel = flexSliders.parents('.pane-bundle-carousel');
      if (carousel.length <= 0 && flexSliders.length > 0) {
        flexSliders.flexslider(Object.values(Drupal.settings.flexslider.optionsets)[0]);
      }
    }
  };

  /**
   * Override default slider behavior.
   *
   * Slide animation causing some issues on larger sliders.
   * Replace with fade.
   */
  Drupal.behaviors.dexcom_slider_fix_fade = {
    attach: function(context, settings) {
      var flexSliders = $('.slider-fix-fade .flexslider');
      if (flexSliders.length > 0) {
        if ($('.slider-fix-fade').length) {
          flexSliders.flexslider({
            animation: 'fade'
          });
        }
      }
    }
  };

}(jQuery));
