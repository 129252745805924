jQuery(document).ready(function () {
  'use strict';
  if (jQuery('#faq-search').length > 0) {
    jQuery(document).ajaxStart(function () {
      jQuery('#loading').addClass('active');
    }).ajaxComplete(function () {
      jQuery('#loading').removeClass('active');
      //Expand the first accordion tab after reload
      // console.log((!jQuery('.accordion__title').hasClass('active')));
      if (!jQuery('.accordion__title:first-child').hasClass('active')) {
        jQuery('.accordion__list .accordion__title:first-child').trigger('click');
      }
    });

    if (!jQuery('.accordion__title:first-child').hasClass('active')) {
      jQuery('.accordion__list .accordion__title:first-child').trigger('click');
    }

    jQuery('.faq-search').on('change', '#edit-category-dropdown', function () {
      var dropDownValue = jQuery(this).val();

      if (dropDownValue === 'All') {
        dropDownValue = 'all';
      }

      jQuery('.bef-select-as-radios').prop('checked', false);
      jQuery('#edit-category-' + dropDownValue).prop('checked', true);

      jQuery('label[for="edit-category-' + dropDownValue + '"]').trigger('change');
    });
  }
});
