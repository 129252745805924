jQuery(document).ready(function () {
  'use strict';
  jQuery(document).on('click', '.accordion__title', function (e) {
    var $accordion = jQuery(e.currentTarget).closest('.accordion'),
      $questions = $accordion.find('.accordion__title'),
      $answers = $accordion.find('.accordion__expanded'),
      $targetQuestion = jQuery(e.currentTarget),
      $targetAnswer = $targetQuestion.next('.accordion__expanded'),
      active = $targetQuestion.hasClass('active');

    e.preventDefault();
    jQuery.when($answers.slideUp(300)).then(function () {
      $questions.add($answers).removeClass('active');
      if (!active) {
        $targetQuestion.add($targetAnswer).addClass('active');
        $targetAnswer.slideDown(300);
      }
    });
  });
});
