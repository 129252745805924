(function ($) {
  'use strict';

  Drupal.behaviors.dexcom_header = {
    attach: function (context, settings) {
      Drupal.behaviors.dexcom_header.positionQuickLinks();
      $(window).resize(function () {
        Drupal.behaviors.dexcom_header.positionQuickLinks();
      });
      Drupal.behaviors.dexcom_header.sticky();
      Drupal.behaviors.dexcom_header.responsiveMenu();
      Drupal.behaviors.dexcom_header.dropDown();
      Drupal.behaviors.dexcom_header.regions();
      Drupal.behaviors.dexcom_header.searchBtn();
    },


    /**
     * Sticky nav bar as you scroll it adds or remove class name.
     */
    sticky: function () {
      var stickyNav,
        headerWrapper = document.querySelector('.header__bar'),
        lastScroll = 0;

      try {
        stickyNav = new Waypoint({
          element: headerWrapper,
          handler: function (direction) {
            if (direction === 'down') {
              headerWrapper.classList.add('stuck');
            } else {
              headerWrapper.classList.remove('stuck');
              // $('.header__bar .header__main-nav').attr('style', '');
            }
          },
          offset: -1
        });
      } catch (e) {
        return false;
      }

      if (typeof stickyNav === 'undefined') {
        return false;
      }
    },

    /**
     * Position Quick Links Menu
     */
    positionQuickLinks: function () {
      var rightPos = $('.block-dexcom-header-language-switch .lang-switch-buttons').width();
      $('.block-quick-links').css({'right': rightPos});
    },

    /**
     * Responsive Menu
     */
    responsiveMenu: function () {
      $('.header__mobile-link').on('click', function (event) {
        var _this = $(this),
          mainNavContainer = _this.data('container'),
          regionHeight;

        event.preventDefault();

        //Close search if it is open
        $('.search-link.is-open').trigger('click');

        _this.toggleClass('is-open');
        $('.' + mainNavContainer).toggleClass('is-open');

        if ($('.' + mainNavContainer).hasClass('moves-out')) {
          $('.' + mainNavContainer).removeClass('moves-out');
        }

        $('.mobile-nav__parent-link').removeClass('is-open');
        $('.mobile-nav__group-links').removeClass('is-open');
        $('.regions--mobile').removeClass('is-open');
      });
    },

    /**
     * Drop Down Menu
     */
    dropDown: function () {
      $('.mobile-nav__parent-link').on('click', function (event) {
        var _this = $(this),
          mainNav = $('.main-nav'),
          menuHeight;

        event.preventDefault();

        if (!mainNav.hasClass('moves-out')) {
          mainNav.addClass('moves-out');
        } else {
          mainNav.removeClass('moves-out');
        }

        if (!_this.hasClass('is-open')) {
          _this.addClass('is-open');
        } else {
          _this.removeClass('is-open');
        }

        if (!_this.next('.mobile-nav__group-links').hasClass('is-open')) {
          _this.next('.mobile-nav__group-links').addClass('is-open');
          menuHeight = _this.next('.mobile-nav__group-links').find('.main-nav-drop-down__container').height();
          $('.main-nav__inner-wrapper').css({'min-height': menuHeight});
        } else {
          _this.next('.mobile-nav__group-links').removeClass('is-open');
        }
      });

      //Go Back Link
      $('.mobile-nav__go-back').on('click', function (event) {
        var _this = $(this);

        event.preventDefault();

        _this.closest('.main-nav__item').find('.mobile-nav__parent-link').removeClass('is-open');
        _this.closest('.mobile-nav__group-links').removeClass('is-open');
        _this.closest('.main-nav').removeClass('moves-out');
        $('.main-nav__inner-wrapper').css({'min-height': 0});
      });
    },

    /**
     * Regions container to toggle class name.
     */
    regions: function () {
      $('.quick-links__link--regions').on('click', function (event) {
        var _this = $(this),
          regionsContainer = _this.data('container');

        event.preventDefault();

        $('.' + regionsContainer).toggleClass('is-open');
      });

      $('.regions__close-link').on('click', function (event) {
        var _this = $(this),
          regionsContainer = _this.data('container');

        event.preventDefault();

        _this.closest('.regions').toggleClass('is-open');
      });
    },

    /**
     * Click event on search bar container.
     */
    searchBtn: function () {
      $('.search-link').on('click', function (event) {
        var _this = $(this),
          searchBarContainer = _this.data('container');

        event.preventDefault();

        //Close Main nav if it is open
        $('.header__mobile-link.is-open').trigger('click');

        _this.toggleClass('is-open');
        $('.' + searchBarContainer).toggleClass('is-open');
      });
    }
  };
}(jQuery));
