(function ($) {
  'use strict';

  Drupal.dexcom_modals = {};

  Drupal.dexcom_modals.bindOpenBtn = function () {
    var dm = Drupal.dexcom_modals;

    $('[data-trigger=modal]').on('click', function (e) {
      // if ($(e.currentTarget).data('trigger-mobile') !== false || window.innerWidth > 767) {
      // e.preventDefault();
      // dm.open($(this).data('target'));
      // }
      e.preventDefault();
      dm.open($(this).data('target'));
    });
  };

  Drupal.dexcom_modals.bindCloseBtn = function () {
    var dm = Drupal.dexcom_modals;

    $('.modal__close').on('click.modal__close', function (e) {
      dm.close();
    });
  };

  Drupal.dexcom_modals.autoplayVideo = function ($iframe) {
    var autoplayString = 'autoplay=1',
      src = $iframe.attr('src'),
      separator = '?';

    if (src === '') {
      src = $iframe.data('src');
    }

    // Store the original src for later
    $iframe.data('src', src);

    if (src.indexOf('?') > -1) {
      separator = '&';
    }

    if (src.indexOf(autoplayString) === -1) {
      $iframe.attr('src', src + separator + autoplayString);
    }
  };

  Drupal.dexcom_modals.iframeIsVideo = function ($iframe) {
    var domains = ['youtube.com', 'vimeo.com'],
      i,
      len = domains.length,
      src = $iframe.data('src') || $iframe.attr('src');

    for (i = 0; i < len; i++) {
      if (src.indexOf(domains[i]) > -1) {
        return true;
      }
    }
  };

  Drupal.dexcom_modals.open = function (id) {
    var dm = Drupal.dexcom_modals,
      $modal,
      $iframe;

    if ($('#' + id).length) {
      $('body').addClass('modal-open');
      $('.modal-overlay').show();
      $modal = $('#' + id).show();

      $iframe = $modal.find('iframe');
      if ($iframe.length && dm.iframeIsVideo($iframe)) {
        dm.autoplayVideo($iframe);
      }
    } else {
      // console.warn('Specified modal (#' + id + ') does not exist!');
    }
  };

  Drupal.dexcom_modals.close = function () {
    var $modals = $('.modal:visible'),
      $iframes = $modals.find('iframe'),
      dm = Drupal.dexcom_modals;

    $('body').removeClass('modal-open');
    $modals.add('.modal-overlay').hide();

    // Reset iframe src to stop video playing
    if ($iframes.length) {
      $iframes.each(function () {
        if (dm.iframeIsVideo($(this))) {
          $(this).attr('src', '');
        }
      });
    }
  };

  Drupal.behaviors.dexcom_modals = {
    attach: function (context, settings) {
      if ($('[data-trigger=modal]').length) {
        Drupal.dexcom_modals.bindOpenBtn();
      }
      if ($('.modal__close').length) {
        Drupal.dexcom_modals.bindCloseBtn();
      }
    }
  };
}(jQuery));
