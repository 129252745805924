/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
(function ($) {
  $(document).ready(function () {
    $('.sendmail-triger-hide').hide();
    $('.sendmail-block').hide();
    $('.sendmail-triger-show').click(function () {
      $(this).hide();
      $(this).parent().children('.sendmail-block').children('.sendmail-triger-hide').show();
      $(this).parent().children('.sendmail-block').slideDown();
    });
    $('.sendmail-triger-hide').click(function () {
      $(this).hide();
      $(this).parent().parent().children('.sendmail-triger-show').show();
      $(this).parent('.sendmail-block').slideUp();
    });
    if (typeof $.fn.multipleSelect !== 'undefined') {
      $('.form-item-field-matrix-category-tid select').multipleSelect({
        placeholder: 'Any category',
        refresh: true
      });
      $(document).ajaxSuccess(function () {
        $('.form-item-field-matrix-category-tid select').multipleSelect({
          placeholder: 'Any category',
          refresh: true
        });
      });
    }
    $('.search-trigger').click(function () {
      $('.l-header-main').toggle();
    });
    $('.responsive-menu').click(function () {
      $('#block-system-main-menu').toggle();
    });
    $('.responsive-menu').click(function () {
      if ($(this).hasClass('res-show')) {
        $(this).addClass('res-hide').removeClass('res-show');
        $('#block-system-main-menu').animate({right: '0', width: '100%', height: '100%'});
      } else if ($(this).hasClass('res-hide')) {
        $(this).addClass('res-show').removeClass('res-hide');
        $('#block-system-main-menu').slideUp('slow');
      }
    });
  });
})(jQuery);

