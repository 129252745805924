(function ($) {
  'use strict';

  Drupal.dexcom_general = {};

  /**
   * Disclaimer close btn.
   */
  Drupal.dexcom_general.disclaimer = function () {
    $('.disclaimer__inner-wrapper .icon').on('click', function () {
      this.parentElement.remove();
    });
  };

  /**
   * Custom equalHeights, based on the one in vendors.js
   *
   * @see vendors.js
   */
  Drupal.dexcom_general.equalHeights = function (container, minimumHeight) {
    var minHeight = (typeof minimumHeight === 'undefined') ? 420 : minimumHeight;

    // loop through each row. Note: The parent() must be the row of 'container'
    $(container).parent().each(function () {
      var el = $(this);
      var topPosition = el.position().top;
      var newMinHeight = minHeight;
      el.height('auto');

      // loop through each item in each row, if height is greater than min-height set min-height
      // to height of item, else set item to min-height.
      el.children(container).each(function() {
        if ($(this).height() > newMinHeight) {
          newMinHeight = $(this).height();
        }
      });
      el.children(container).height(newMinHeight).css('overflow','auto');
    });
  };

  /**
   * equal height touts.
   */
  Drupal.dexcom_general.toutEqualHeight = function () {
    $(window).load(function () {
      Drupal.dexcom_general.equalHeights('.tout__wrapper', 0);
      Drupal.dexcom_general.outerEqualHeight('.tout__wrapper.twentyfive-seventyfive', 0);
    });

    $(window).resize(function () {
      Drupal.dexcom_general.equalHeights('.tout__wrapper', 0);
      Drupal.dexcom_general.outerEqualHeight('.tout__wrapper.twentyfive-seventyfive', 0);
    });
  };

  /**
   * equal height touts.
   */
  Drupal.dexcom_general.outerEqualHeight = function (container, minHeight) {
    var el, newMinHeight = (typeof minHeight === 'undefined') ? 420 : minHeight;

    // loop through each row. Note: The parent() must be the row of 'container'
    $(container).each(function () {
      el = $(this);

      // loop through each item in each row, if height is greater than min-height set min-height
      // to height of item, else set item to min-height.
      if (el.outerHeight() > newMinHeight) {
        newMinHeight = el.outerHeight();
      }
    });

    $(container).height(newMinHeight);
  };

  /**
   * Scroll to error messages
   */
  Drupal.dexcom_general.scrollToErrorMessages = function () {
    var headerHeight;

    if ($('#messages .error').length) {
      headerHeight = Math.max($('.header').outerHeight(), $('.header .header__bar').outerHeight());

      $(window).load(function () {
        $('html, body').animate({
          scrollTop: $('#messages .error').offset().top - headerHeight
        }, 1000);
      });
    }
  };

  Drupal.dexcom_general.isMobile = function () {
    try {
      document.createEvent('TouchEvent');
      return true;
    } catch (e) {
      return false;
    }
  };

  Drupal.dexcom_general.videoMarquee = function () {
    if (Drupal.dexcom_general.isMobile()) {
      $('.marquee__video-bg-wrapper').css('display', 'none');
    }
  };

  Drupal.dexcom_general.videoPlayFix = function () {
    if ($('#dexcom-fp-promo-video').length) {
      if ($('.marquee__desktop-image').length) {
        // Hide the bg image, if we have a video.
        $('.marquee__desktop-image').hide();
      }
      var promise = $('#dexcom-fp-promo-video').get(0).play().catch(function (error) {
        // If the video could not autoplay, show the bg image, if it exists.
        if ($('.marquee__desktop-image').length) {
          $('.marquee__desktop-image').show();
        }
      });
    }
  };

  Drupal.behaviors.dexcom_general = {
    attach: function (context, settings) {
      Drupal.dexcom_general.disclaimer();
      Drupal.dexcom_general.toutEqualHeight();
      Drupal.dexcom_general.scrollToErrorMessages();
      Drupal.dexcom_general.videoMarquee();
      Drupal.dexcom_general.videoPlayFix();
    }
  };
}(jQuery));
